.bg-dark {
  background: $primaryBlack;
}

// hidden ad
// .ad {
//   color: $primaryBlack;
//   font-weight: $fontWeightBold;
//   font-size: 2rem;
//   line-height: 1;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 6.5rem;
//   margin: 3.1rem 0;

//   @include tabletP {
//     height: 14.2rem;
//     margin: 4rem 0;
//   }

//   @include desk {
//     height: 20rem;
//     margin: 8rem 0;
//   }
//   &--global {
//     background-color: $waveClr;
//     margin: 0;
//     height: 7.2rem;

//     @include tabletP {
//       height: 9.4rem;
//     }

//     @include desk {
//       height: 10rem;
//     }
//   }

//   &--wide {
//     background-color: $waveClr;
//     @include tabletP {
//       margin: 4rem -24.3rem 4rem 0;
//       width: auto;
//     }
//     @include desk {
//       margin: 8rem -25.35rem 8rem 0;
//     }
//   }

//   &__wrapper {
//     background-color: $waveClr;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
//   }
// }

.offset-scroll {
  padding-top: 10.3rem;
  margin-top: -10.3rem;
  @include tabletP {
    padding-top: 12.6rem;
    margin-top: -12.6rem;
  }
}

.hidden {
  display: none;
}
