html {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  font-size: 62.5%;
  font-style: normal;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

// inherited from <html>
*,
*::before,
*::after {
  box-sizing: inherit;
}

*::selection {
  background: $waveClr;
}

body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-size-adjust: 100%; // iOS on orientation change
  color: $primaryBlack;
  font-family: $fontFamilyPrimary;
  font-weight: $fontWeightRegular;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-style: normal;
  font-stretch: normal;
  backface-visibility: hidden;
  overflow-x: hidden;
  background: $primaryWhite;
  font-size: 1.3rem;
  line-height: 2rem;
  @include tabletP {
    line-height: 2.4rem;
  }
  @include desk {
    font-size: 1.4rem;
  }
}
main {
  position: relative;
  flex:1;
}

a,
label,
.button {
  -webkit-tap-highlight-color: transparent;
  transition: $transition-base;
}

button {
  font-family: $fontFamilyPrimary;
  transition: $transition-base;
}

img, picture {
  display: block;
  max-width: 100%;
  height: auto;
}
picture {
  source, img {
    object-fit: cover;
    height: 100%;
  }
}

picture.ad_picture_header {
  source, img {
    object-fit: contain;
    height: 100%;
  }
}

// placeholders
input,
textarea,
select {
  &::-webkit-input-placeholder {
    color: $primaryWhite;
  }

  &::-moz-placeholder {
    opacity: 1;
    color: $primaryWhite;
  }

  &:-ms-input-placeholder {
    color: $primaryWhite;
  }
}

strong {
  font-weight: $fontWeightBold;
}
