@mixin tabletP {
  @media (min-width: #{$breakpointTabletP}) {
    @content;
  }
}

@mixin tabletL {
  @media (min-width: #{$breakpointTabletL}) {
    @content;
  }
}

@mixin desk {
  @media (min-width: #{$breakpointDesk}) {
    @content;
  }
}

@mixin deskHD {
  @media (min-width: #{$breakpointDeskHD}) {
    @content;
  }
}
