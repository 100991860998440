.sidebar {
  background-color: $rightBoxBgrd;
  padding: 2.6rem 0 2.4rem;
  margin: 2.4rem 0;
  @include desk {
    padding: 1.5rem 0 2.4rem;
  }
  h2 {
    margin-bottom: 1.5rem;
    @include tabletP {
      margin-bottom: 3rem;
    }
    @include desk {
      margin-bottom: 0.7rem;
    }
  }
  &__content {
    max-width: 100%;
    padding-inline: 2.1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    @include tabletP {
      padding-inline: 2rem;
    }
  }
  &__item {
    padding: 0 0.9rem;
    padding: 0;
    margin-bottom: 1.1rem;
    @include tabletP {
      margin-bottom: 0.8rem;
    }
    @include tabletL {
      margin-bottom: 1.2rem;
    }
    & + .sidebar__item {
      margin-bottom: 0;
      padding-bottom: 2.4rem;
      border-bottom: 0.2rem $primaryWhite solid;
      @include tabletP {
        padding-bottom: 1.6rem;
      }
      @include desk {
        padding-bottom: 1.9rem;
      }
    }
  }
  &__author {
    color: $primaryGrey;
    margin-top: 0.9rem;
    @include tabletP {
      margin-top: 1rem;
    }
    strong {
      margin-right: 0.1rem;
    }
  }
  &__btn {
    margin-top: 2rem;
    @include tabletP {
      margin-top: 1.7rem;
    }
  }
  &--product {
    padding-bottom: 2.5rem;
    @include tabletP {
      margin-top: 0;
      padding-top: 1rem;
    }
    @include desk {
      padding-top: 3.1rem;
      padding-bottom: 2.9rem;
    }
    h2 {
      margin-bottom: 0.3rem;
      @include tabletP {
        margin-bottom: 0.5rem;
      }
      @include desk {
        margin-bottom: 1.7rem;
      }
    }
    .sidebar {
      &__content {
        padding: 0 2.3rem;
        @include tabletP {
          padding: 0 1.1rem;
        }
        @include desk {
          padding: 0 2.9rem;
        }
      }
      &__item {
        margin-bottom: 0.7rem;
        padding-bottom: 2.4rem;
        border-bottom: 0.2rem $primaryWhite solid;
        @include desk {
          padding-bottom: 2.9rem;
          margin-bottom: 2rem;
        }
        &:last-of-type {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
  &--breed {
    @include tabletP {
      margin-top: 3.2rem;
    }
    @include desk {
      margin-top: 4rem;
    }
    .sidebar {
      &__content {
        padding-bottom: 2.4rem;
        & + .sidebar__content {
          padding-top: 2.4rem;
          padding-bottom: 0;
          border-top: 4px solid $primaryWhite;
        }
      }
    }
  }
}
