.footer {
  background-color: $footerBgrd;
  padding: 2.5rem 0 4.2rem;
  color: $primaryGrey;
  @include tabletP {
    padding: 4rem 0 4rem;
  }
  @include desk {
    padding: 8.9rem 0 7.5rem;
  }
  &__content {
    @include tabletP {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__logo {
    max-width: 8rem;
    display: block;
    margin-bottom: 2.5rem;
    @include tabletP {
      margin-bottom: 2.8rem;
    }
    @include desk {
      max-width: 8.4rem;
      margin-bottom: 2.6rem;
    }
  }
  &__info {
    @include tabletP {
      width: 34%;
    }
    @include desk {
      width: 25%;
    }
    .cols {
      @include tabletP {
        flex-direction: column;
      }
      &__col {
        @include tabletP {
          margin-bottom: 2.4rem;
        }
      }
    }
  }
  &__nav {
    margin-top: 3.8rem;
    @include tabletP {
      margin-top: 0;
      width: 66%;
      padding-left: 2.3rem;
    }
    @include desk {
      width: 50%;
    }
    .cols__col:first-child {
      @include desk {
        width: 33%;
      }
    }
  }
  &__copyright {
    margin-top: 1.6rem;
    @include tabletP {
      margin-top: 3.6rem;
    }
  }
  &__contact {
    margin-top: 3.3rem;
    @include tabletP {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 2.1rem;
    }
    @include desk {
      display: block;
      width: 25%;
      padding-left: 1.3rem;
      margin-top: -0.3rem;
    }
    a {
      display: block;
      @include tabletP {
        display: inline-block;
      }
      @include desk {
        margin-bottom: 0.8rem;
        display: block;
      }
    }
    &__item {
      font-size: 1.3rem;
      line-height: 2rem;
      margin-bottom: 0.2rem;
      @include tabletP {
        margin: 0 4rem 0 0;
        line-height: 2.4rem;
      }
      @include desk {
        margin: 0;
        font-size: 1.4rem;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    .footer__link {
      @include tabletP {
        margin-bottom: 0;
      }
      @include desk {
        margin-bottom: 4.1rem;
      }
    }
  }
  &__social {
    display: flex;
    margin-top: 3.3rem;
    @include tabletP {
      margin: 0;
    }
    @include desk {
      margin-top: 4rem;
    }
    a {
      display: block;
      font-size: 2.4rem;
      padding: 0 0.4rem;
      margin-right: 1.4rem;
      @include desk {
        max-width: 3.2rem;
        margin-right: 1.7rem;
        padding: 0 0.8rem 0 0;
      }
      &:hover {
        span {
          &:before {
            color: $hoverOrange;
          }
        }
      }
      &:last-child {
        margin-right: 0.6rem;
      }
      span {
        &:before {
          transition: $transition-base;
        }
      }
    }
  }
  &__link {
    display: block;
    margin-bottom: 1.1rem;
    color: $primaryViolet;
    font-size: 1.2rem;
    font-weight: $fontWeightBold;
    line-height: 1.8rem;
    cursor: pointer;
    transition: $transition-base;
    @include tabletP {
      margin-bottom: 1.3rem;
      font-size: 1.4rem;
      line-height: 2.1rem;
    }
    &:hover {
      color: $hoverOrange;
    }
  }
}
