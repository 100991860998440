.copyright-footer {
  &__content {
    background-color: $primaryViolet;
    color: $primaryWhite;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    @include desk {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .div-left {
    font-size: 13px;
    float:left;
  }

  .div-right {
    display: flex;
    justify-content: flex-end;
    div {
      font-size: 13px;
    }
  }

  .message {
    color: $primaryBlack;
  }
}
