.container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 47.8rem;
  padding-left: $gridSpacerMobile;
  padding-right: $gridSpacerMobile;

  @include tabletP {
    padding-left: $gridSpacerTabletP;
    padding-right: $gridSpacerTabletP;
    max-width: 76.8rem;
  }

  @include tabletL {
    padding-left: $gridSpacerTabletL;
    padding-right: $gridSpacerTabletL;
    max-width: 102.4rem;
  }

  @include desk {
    padding-left: 0;
    padding-right: 0;
    max-width: 144rem;
  }
}

.container-narrow {
  @include desk {
    max-width: 144rem;
    margin: 0 auto;
  }
}

.cols {
  display: flex;
  margin: 0 -0.9rem;
  &__col {
    flex-basis: 100%;
    padding: 0 0.9rem;
    @include desk {
      flex-basis: auto;
    }
    &--column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  @include tabletP {
    flex-wrap: nowrap;
  }
  &__content {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 0;
    @include tabletP {
      flex-basis: calc(100% - 22.3rem);
      padding-right: 2rem;
    }
    @include desk {
      flex-basis: calc(100% - 23.4rem);
    }
  }
  &__sidebar {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    @include tabletP {
      flex-basis: 22.3rem;
    }
    @include desk {
      flex-basis: 23.4rem;
    }
    &--article {
      margin-top: 3.2rem;
      @include tabletP {
        margin-top: 4rem;
      }
    }
  }
  &--featured-posts {
    .grid__content {
      position: relative;
      z-index: 1;
      @include desk {
        flex-basis: calc(100% - 31.9rem);
      }
    }
    .grid__sidebar {
      z-index: 1;
      @include desk {
        flex-basis: 31.9rem;
      }
    }
  }
  &--home-page {
    @include tabletP {
      flex-wrap: wrap;
    }
    @include desk {
      flex-wrap: nowrap;
    }
    .grid__content {
      @include tabletP {
        flex-basis: 100%;
        padding-right: 0;
      }
      @include desk {
        flex-basis: calc(100% - 23.4rem);
        padding-right: 2rem;
      }
    }
    .grid__sidebar {
      @include tabletP {
        display: flex;
        flex-basis: 100%;
      }
      @include desk {
        display: block;
        flex-basis: 23.4rem;
        position: absolute;
        max-width: 23.4rem;
        top: 6rem;
        right: calc((100% - 99.9rem) / 2);
      }
    }
  }
  &--filters {
    .grid__content {
      @include tabletP {
        flex-basis: calc(100% - 22.2rem);
        padding-right: 0;
        padding-left: 2.1rem;
      }
      @include desk {
        flex-basis: calc(100% - 23.2rem);
      }
    }
    .grid__sidebar {
      @include tabletP {
        flex-basis: 22.2rem;
      }
      @include desk {
        flex-basis: 23.2rem;
      }
    }
  }
  &--contact {
    @include tabletP {
      margin-top: 1.5rem;
    }
    @include tabletL {
      margin-top: 3rem;
    }
    .grid__content {
      @include tabletP {
        flex-basis: calc(100% - 34.3rem);
        padding-right: 0;
        padding-left: 2.1rem;
      }
      @include tabletL {
        flex-basis: calc(100% - 31.9rem);
        padding-left: 10.3rem;
        margin-top: 2rem;
      }
    }
    .grid__sidebar {
      @include tabletP {
        flex-basis: 34.3rem;
      }
      @include tabletL {
        flex-basis: 31.9rem;
      }
    }
  }
}
