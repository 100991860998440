.btn {
  position: relative;
  font-size: 1.2rem;
  font-weight: $fontWeightBold;
  line-height: 1.8rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $primaryWhite;
  border-radius: 4.6rem;
  padding: 0.9rem 1.9rem 0.9rem 2rem;
  z-index: 1;
  transition: $transition-base;
  span {
    margin-left: 1rem;
    font-size: 1.4rem;
    &:before {
      transition: $transition-base;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 4.6rem;
    border: 2px solid $primaryWhite;
    transition: $transition-base;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    background: $secondaryBtn;
    width: calc(100% - 0.3rem);
    height: 100%;
    border-radius: 4.6rem;
    z-index: -1;
    transition: $transition-base;
  }
  &:hover, &--active {
    &::after {
      background: $hoverOrange;
    }
  }
  &--block {
    width: 100%;
  }
  &--blue {
    color: $primaryViolet;
    &::before {
      border-color: $primaryViolet;
    }
    &::after {
      background: $primaryBtn;
    }
    &:hover {
      color: $primaryWhite;
      &::before {
        border-color: $primaryWhite;
      }
      &::after {
        background: $hoverOrange;
      }
      span {
        &:before {
          color: $primaryWhite;
        }
      }
    }
    span {
      &:before {
        color: $primaryViolet;
      }
    }
  }
  &--white {
    color: $primaryViolet;
    &:hover {
      color: $primaryWhite;
      &::before {
        border-color: $primaryWhite;
      }
      &::after {
        background: $hoverOrange;
      }
      span {
        &:before {
          color: $primaryWhite;
        }
      }
    }
    &::before {
      border-color: $primaryViolet;
    }
    &::after {
      background: $primaryWhite;
    }
    span {
      &:before {
        color: $primaryViolet;
      }
    }
  }
  &--link {
    padding: 0;
    color: $primaryViolet;
    text-transform: uppercase;
    &:hover {
      color: $hoverOrange;
      span {
        &:before {
          color: $hoverOrange;
        }
      }
    }
    &::before, &::after {
      display: none;
    }
    span {
      margin-left: 0.7rem;
      @include tabletP {
        margin-left: 0.8rem;
      }
      &:before {
        transition: $transition-base;
        color: $primaryViolet;
      }
    }
  }
  &--pagination {
    width: 2.8rem;
    height: 2.8rem;
    padding: 0;
    &:hover {
      &::before {
        border-color: $primaryWhite;
      }
      &::after {
        background: $hoverOrange;
      }
      span {
        &::before {
          color: $primaryWhite;
        }
      }
    }
    &::before {
      width: 2.8rem;
      height: 2.8rem;
      border-color: $primaryViolet;
    }
    &::after {
      width: 2.6rem;
      height: 2.6rem;
      top: 0.3rem;
      left: 0.3rem;
      background: $paginationBtn;
    }
    span {
      margin-left: 0;
      margin-right: 0.4rem;
      display: inline-block;
      transform: rotate(90deg);
      font-size: 0.9rem;
      font-weight: $fontWeightBold;
      &::before {
        color: $primaryViolet;
      }
    }
  }
  &--pagination-next {
    span {
      margin-right: 0;
      margin-left: 0.4rem;
      transform: rotate(-90deg);
    }
  }
  &--simple {
    background: $primaryBlue;
    font-weight: 400;
    &::before, &::after {
      display: none;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
      &::after {
        opacity: 0.4;
      }
  }
}
