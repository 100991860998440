.message {
  font-size: 1.2rem;
  line-height: 2.4rem;
  padding: 0.4rem 0.4rem 0.4rem 2.7rem;
  position: relative;
  display: block;
  margin-bottom: 2.4rem;
  @include tabletP {
    padding-right: 3rem;
    display: inline-block;
  }
  &--success {
    background-color: $rightBoxBgrd;
    .message__icon {
      &::after {
        content: '';
        border: solid $primaryGreen;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
        position: absolute;
        width: 0.4rem;
        height: 0.7rem;
        left: 1rem;
        top: 50%;
        margin-top: -0.4rem;
      }
    }
  }
  &--error {
    background-color: $secondaryError;
    .message__icon {
      &::after {
        content: '';
        height: 1px;
        width: 0.7rem;
        background-color: $primaryError;
        transform: rotate(45deg);
        position: absolute;
        left: 1rem;
        top: 50%;
        margin-top: -0.1rem;
      }
      &::before {
        content: '';
        height: 1px;
        width: 0.7rem;
        background-color: $primaryError;
        transform: rotate(-45deg);
        position: absolute;
        left: 1rem;
        top: 50%;
        margin-top: -0.1rem;
      }
    }
  }
  &--hide {
    display: none;
  }
}
