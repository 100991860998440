.authors-slider {
  background-color: $rightBoxBgrd;
  margin: 1rem 0;
  width: calc(100vw - 6rem);
  max-width: 43rem;
  @include tabletL {
    max-width: 24rem;
  }
  @include tabletP {
    max-width: 24rem;
  }
  @include desk {
    max-width: 24rem;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.3rem;
    @include tabletP {
      margin-bottom: 1.1rem;
    }
  }
  &__buttons {
    .btn--pagination-prev {
      margin-right: 0.7rem;
    }
    .swiper-button-disabled {
      pointer-events: none;
      opacity: 0.4;
      &::after {
        opacity: 0.4;
      }
    }
  }
  &__swiper {
    margin-bottom: 0;
    h4 {
      color: $primaryViolet;
      @include tabletP {
        margin-bottom: 0.5rem;
      }
      @include desk {
        margin-bottom: 0.1rem;
      }
    }
    .swiper-slide {
      opacity: 1 !important;
    }
    .swiper-wrapper {
      padding-bottom: 0;
    }
    .post-thumbnail {
      display: block;
      .details-icon__item {
        min-width: auto;
      }
    }
    &--specialists {
      .authors-slider {
        &__swiper {
          h4 {
            margin-bottom: 0.7rem;
          }
        }
        &__img {
          img {
            object-fit: contain;
            height: 9.3rem;//15
          }
        }
      }
    }
  }
  &__img {
    width: 100%;
    display: inline-block;
    margin-bottom: 1rem;
    @include tabletP {
      margin-bottom: 1.45rem;
    }
    img {
      min-height: 9.7rem;
      display: inline-block;
      border-radius: 0.6rem;
      max-height: unset;
      margin-left: 3.5rem;
      @include tabletP {
        min-height: 10.5rem;
        width: auto;
        margin-left: 0.5rem;
      }
      @include desk {
        width: auto;
        min-height: 10.8rem;
        display: flex;
        margin-left: 0.5rem;
      }
    }
  }
}

.authors-slider-text {
  &__gray {
    text-transform: uppercase;
  }
  text-align: center;
}
