.tags {
  &__item {
    display: inline-block;
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    color: $primaryGrey;
    margin-right: 0.3rem;
    &::after {
      content: '';
      display: inline-block;
      width: 2px;
      height: 2px;
      background-color: $primaryGrey;
      position: relative;
      top: -0.3rem;
      right: -0.3rem;
    }
    &:last-of-type {
      margin-right: 0;
      &::after {
        display: none;
      }
    }
  }
}
