@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?ppb3zq');
  src:  url('../../fonts/icomoon.eot?ppb3zq#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon.ttf?ppb3zq') format('truetype'),
  url('../../fonts/icomoon.woff?ppb3zq') format('woff'),
  url('../../fonts/icomoon.svg?ppb3zq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  // font-display: block;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-medal:before {
  content: "\e92a";
}
.icon-globe:before {
  content: "\e92b";
  color: #4e25b0;
}
.icon-envelope:before {
  content: "\e92c";
  color: #4e25b0;
}
.icon-phone:before {
  content: "\e92d";
  color: #4e25b0;
}
.icon-point:before {
  content: "\e92e";
  color: #4e25b0;
}
.icon-loop:before {
  content: "\e929";
  color: #4e25b0;
}
.icon-size:before {
  content: "\e926";
  color: #717171;
}
.icon-sex:before {
  content: "\e927";
  color: #717171;
}
.icon-calendar:before {
  content: "\e928";
  color: #717171;
}
.icon-starFill:before {
  content: "\e925";
  color: #f1a93c;
}
.icon-star:before {
  content: "\e924";
  color: #f1a93c;
}
.icon-wave:before {
  content: "\e915";
  color: #d8f4ff;
}
.icon-quote:before {
  content: "\e916";
  color: #e6f8ff;
}
.icon-share:before {
  content: "\e917";
  color: #fff;
}
.icon-twitterColor .path1:before {
  content: "\e918";
  color: rgb(85, 172, 238);
}
.icon-twitterColor .path2:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mailColor .path1:before {
  content: "\e91a";
  color: rgb(255, 255, 255);
}
.icon-mailColor .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(66, 133, 244);
}
.icon-mailColor .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-mailColor .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(251, 188, 4);
}
.icon-mailColor .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.icon-mailColor .path6:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(197, 34, 31);
}
.icon-linkedinColor .path1:before {
  content: "\e920";
  color: rgb(0, 119, 181);
}
.icon-linkedinColor .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-facebookColor:before {
  content: "\e922";
  color: #1877f2;
}
.icon-home:before {
  content: "\e923";
  color: #717171;
}
.icon-arrowMenu:before {
  content: "\e904";
  color: #00d7ff;
}
.icon-menuWhite .path1:before {
  content: "\e90c";
  color: rgb(172, 236, 248);
}
.icon-menuWhite .path2:before {
  content: "\e90d";
  margin-left: -1.0341796875em;
  color: rgb(255, 255, 255);
}
.icon-closeWhite .path1:before {
  content: "\e913";
  color: rgb(172, 236, 248);
}
.icon-closeWhite .path2:before {
  content: "\e914";
  margin-left: -1.0341796875em;
  color: rgb(255, 255, 255);
}
.icon-Warning .path1:before {
  content: "\e900";
  color: rgb(217, 217, 217);
  opacity: 0.0100;
}
.icon-Warning .path2:before {
  content: "\e901";
  margin-left: -1.142578125em;
  color: rgb(222, 19, 6);
}
.icon-Warning .path3:before {
  content: "\e902";
  margin-left: -1.142578125em;
  color: rgb(222, 19, 6);
}
.icon-Warning .path4:before {
  content: "\e903";
  margin-left: -1.142578125em;
  color: rgb(222, 19, 6);
}
.icon-ButtonPaw:before {
  content: "\e905";
  color: #fff;
}
.icon-Facebook:before {
  content: "\e906";
  color: #4e25b0;
}
.icon-icoUiAccount .path1:before {
  content: "\e907";
  color: rgb(172, 236, 248);
}
.icon-icoUiAccount .path2:before {
  content: "\e908";
  margin-left: -1.0341796875em;
  color: rgb(172, 236, 248);
}
.icon-icoUiAccount .path3:before {
  content: "\e909";
  margin-left: -1.0341796875em;
  color: rgb(255, 255, 255);
}
.icon-icoUiAccount .path4:before {
  content: "\e90a";
  margin-left: -1.0341796875em;
  color: rgb(255, 255, 255);
}
.icon-icoUiAccount .path5:before {
  content: "\e90b";
  margin-left: -1.0341796875em;
  color: rgb(255, 255, 255);
}
.icon-icoUiSearch .path1:before {
  content: "\e90e";
  color: rgb(172, 236, 248);
}
.icon-icoUiSearch .path2:before {
  content: "\e90f";
  margin-left: -1.0166015625em;
  color: rgb(255, 255, 255);
}
.icon-Instagram:before {
  content: "\e910";
  color: #4e25b0;
}
.icon-paw:before {
  content: "\e911";
  color: #fff;
}
.icon-Twitter:before {
  content: "\e912";
  color: #4e25b0;
}
.icon-pin .path1:before {
  content: "\e926";
  color: rgb(136, 136, 136);
}
.icon-pin .path2:before {
  content: "\e927";
  margin-left: -0.7275390625em;
  color: rgb(255, 255, 255);
}
