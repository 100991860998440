.author {
  display: flex;
  align-items: center;
  picture {
    width: 4.7rem;
    height: 4.7rem;
    margin-right: 1rem;
    img {
      border-radius: 50%;
    }
  }
  &__info {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  &__name {
    color: $primaryViolet;
    font-weight: $fontWeightBold;
    transition: $transition-base;
  }
  &--big {
    min-height: 8rem;
    align-items: flex-start;
    picture {
      width: 6.5rem;
      height: 6.5rem;
      margin-right: 1.7rem;
    }
    p {
      color: $primaryGrey;
      text-transform: uppercase;
      margin-top: 1rem;
      @include desk {
        margin-top: 0.7rem;
      }
    }
    .author__info {
      flex-basis: calc(100% - 8.7rem);
      margin-top: 0.7rem;
      @include desk {
        margin-top: 0.6rem;
      }
    }
  }
}
