.thumbnail-slider {
  margin: 2.6rem 0;
  @include tabletP {
    margin: 3.3rem 0 6rem;
  }
  @include desk {
    margin: 6rem 0 6rem;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.3rem;
    @include tabletP {
      margin-bottom: 1.1rem;
    }
  }
  &__buttons {
    .btn--pagination-prev {
      margin-right: 0.7rem;
    }
    .swiper-button-disabled {
      pointer-events: none;
      opacity: 0.4;
      &::after {
        opacity: 0.4;
      }
    }
  }
  &__swiper {
    margin-bottom: 0;
    h4 {
      color: $primaryViolet;
      @include tabletP {
        margin-bottom: 0.5rem;
      }
      @include desk {
        margin-bottom: 0.1rem;
      }
    }
    .swiper-slide {
      opacity: 1 !important;
    }
    .swiper-wrapper {
      padding-bottom: 0;
    }
    .post-thumbnail {
      display: block;
      &__teaser {
        @include tabletP {
          margin-top: 0.7rem;
        }
        @include desk {
          margin-top: 1.2rem;
        }
      }
      .details-icon__item {
        min-width: auto;
      }
    }
    &--specialists {
      .thumbnail-slider {
        &__swiper {
          h4 {
            margin-bottom: 0.7rem;
          }
        }
        &__img {
          img {
            object-fit: contain;
            @include tabletP {
              min-height: 15.3rem;
            }
          }
        }
        &__button {
          margin-top: 1.4rem;
          @include tabletP {
            margin-top: 2rem;
          }
          @include desk {
            margin-top: 1.9rem;
          }
        }
      }
    }
  }
  &__img {
    width: 100%;
    display: inline-block;
    margin-bottom: 1rem;
    @include tabletP {
      margin-bottom: 1.45rem;
    }
    img {
      min-height: 10.7rem;
      display: inline-block;
      border-radius: 0.6rem;
      max-height: unset;
      @include tabletP {
        min-height: 15.5rem;
        width: auto;
      }
      @include desk {
        width: auto;
        min-height: 15.8rem;
        display: flex;
      }
    }
  }
  &__button {
    @include tabletP {
      margin-top: 2.5rem;
    }
    @include desk {
      margin-top: 2.2rem;
    }
  }
}

.flex-container-spec{
  display: flex;
  margin-bottom: 30px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 30px;
}
