.article-banner {
  position: relative;
  background: $sectionBgrd;
  padding-bottom: 3.2rem;
  @include tabletP {
    padding-bottom: 2.3rem;
  }
  @include desk {
    padding-bottom: 3.9rem;
  }
  &__slider {
    position: relative;
    @include tabletP {
      margin-top: -1.2rem;
    }
  }
  &__content {
    position: relative;
    z-index: 1;
    @include tabletP {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      min-height: 26.5rem;
    }
    @include desk {
      min-height: 39.5rem;
    }
  }
  &__hero {
    margin-left: 9.4rem;
    margin-right: auto;
    @include tabletP {
      margin: -1.4rem 3.8rem 0 0;
    }
    @include desk {
      margin: 0 10.3rem 0 0;
    }
    img {
      width: 19.7rem;
      max-height: 20.4rem;
      @include tabletP {
        max-height: 28.19rem;
        width: 28.9rem;
      }
      @include tabletL {
        max-height: 39.9rem;
        width: 38.5rem;
      }
    }
  }
  &__hero-source {
    display: block;
    word-break: break-all;
    padding: 1rem 0;
    @include tabletP {
      width: 28.9rem;
    }
    @include tabletL {
      width: 38.5rem;
    }
  }
  &__text {
    margin-top: 0.7rem;
    @include tabletP {
      width: 46%;
      margin-top: -4.8rem;
    }
    @include desk {
      width: 90rem;
      margin-top: -5.7rem;
    }
    h1, .header-h1 {
      margin-top: 0.6rem;
      color: $primaryViolet;
      @include tabletP {
        margin-top: 0.4rem;
      }
      @include desk {
        margin-top: 1.3rem;
      }
    }
    p {
      margin-top: 2.1rem;
      @include tabletP {
        margin-top: 2.4rem;
        margin-bottom: 3rem;
        width: 75%;
      }
      @include desk {
        margin-top: 2.1rem;
        width: 60%;
      }
    }
  }
  &__details {
    position: relative;
    z-index: 1;
    margin-top: 3.2rem;
    @include tabletP {
      display: flex;
      justify-content: space-between;
      margin-top: 0;
    }
    @include desk {
      margin-top: 0.1rem;
    }
  }
  &--slider {
    padding-bottom: 0;
    .article-banner {
      &__content {
        @include tabletP {
          align-items: flex-start;
        }
        @include desk {
          margin-top: 0.4rem;
          align-items: center;
        }
        &:hover {
          .article-banner__text .header-h1 {
            color: $hoverOrange;
          }
        }
      }
      &__hero {
        margin-left: 7.3rem;
        @include tabletP {
          margin: -1.2rem 0.1rem 0 0;
        }
        @include desk {
          margin: 1rem 10.4rem 0 0;
        }
        img {
          max-width: 19.7rem;
          @include tabletP {
            max-width: 27.2rem;
          }
          @include tabletL {
            max-width: 44.7rem;
          }
        }
      }
      &__text {
        @include tabletP {
          width: 62%;
          margin-top: 1rem;
        }
        @include desk {
          width: 49%;
          margin-top: -4.1rem;
        }
        .header-h1 {
          text-transform: none;
          font-size: 2rem;
          line-height: 3rem;
          transition: $transition-base;
          @include tabletP {
            font-size: 2.8rem;
            line-height: 3.7rem;
            margin-top: 2.2rem;
          }
          @include desk {
            font-size: 3.2rem;
            line-height: 3.7rem;
            margin-top: 1.3rem;
          }
        }
      }
      &__details {
        @include tabletP {
          margin-top: 0.3rem;
        }
        @include desk {
          margin-top: -0.3rem;
        }
      }
    }
  }
  &--home-slider {
    padding-top: 3.2rem;
    @include tabletP {
      padding-top: 4.2rem;
    }
    @include desk {
      padding-top: 1.2rem;
    }
    // .article-banner {
    //   &__slider {
    //     @include desk {
    //       padding-right: 24.4rem;
    //     }
    //   }
    //   &__hero {
    //     @include desk {
    //       margin: 5.6rem 1.5rem 0 0;
    //     }
    //     img {
    //       @include desk {
    //         max-width: 38.4rem;
    //       }
    //     }
    //   }
    //   &__text {
    //     @include desk {
    //       width: 47%
    //     }
    //     p {
    //       @include desk {
    //         width: 90%;
    //       }
    //     }
    //   }
    // }
  }
}
