.header {
  position: relative;
  width: 100%;
  background-color: $primaryViolet;
  z-index: 3;
  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
  &--fixed-polish {
    position: fixed;
    left: 0;
    top: 39px;
  }
  &__content {
    min-height: 10.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include tabletP {
      min-height: 12.6rem;
    }
    @include desk {
      padding-top: 0.3rem;
    }
  }
  &__logo {
    flex-basis: 10.4rem;
    flex-grow: 0;
    flex-shrink: 0;
    @include tabletP {
      flex-basis: 14.7rem;
    }
  }
  &__buttons {
    display: flex;
    margin-right: -1.5rem;
    @include desk {
      align-items: center;
      justify-content: flex-end;
      margin-right: 0;
    }
  }
  &__button {
    margin-left: 0.7rem;
    font-size: 5.7rem;
    line-height: 1;
    @include desk {
      margin-left: 0;
      font-size: 3.6rem;
    }
    &--hamburger {
      @include tabletP {
        margin-right: 0.8rem;
      }
      @include desk {
        display: none;
      }
      .icon-menuWhite {
        display: inline-block;
      }
      .icon-closeWhite {
        display: none;
      }
    }
    &--active {
      .icon-menuWhite {
        display: none;
      }
      .icon-closeWhite {
        display: inline-block;
      }
    }
    >span {
      display: inline-block;
    }
    .icon-icoUiAccount {
      .path1, .path2 {
        &::before {
          transition: $transition-base;
        }
      }
      &:hover {
        .path1, .path2 {
          &::before {
            color: $hoverOrange;
          }
        }
      }
    }
    .icon-icoUiSearch {
      .path1::before {
        transition: $transition-base;
      }
      &:hover .path1::before {
        color: $hoverOrange;
      }
    }
  }
}
.navigation {
  position: absolute;
  top: 10.3rem;
  left: 0;
  display: flex;
  background-color: $primaryViolet;
  width: 100%;
  max-height: calc(100vh - 10.3rem);
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;
  @include tabletP {
    top: 12.6rem;
    overflow: unset;
  }
  @include desk {
    position: static;
    top: 0;
    padding-top: 0;
    max-width: 100%;
    opacity: 1;
    pointer-events: all;
    margin-right: 3rem;
    margin-left: 5rem;
  }
  &__content {
    padding-top: 1.1rem;
    padding-bottom: 0.8rem;
    border-top: 1px solid #8451FF;
    @include tabletP {
      padding-top: 0.9rem;
      display: flex;
      justify-content: space-around;
      padding-bottom: 2.5rem;
      border-top: none;
    }
    @include desk {
      padding-top: 0;
      padding-bottom: 0;
      justify-content: flex-end;
    }
  }
  &--active {
    opacity: 1;
    pointer-events: all;
  }
}

.nav-item.market .nav-item__button:hover,
.nav-item.market .nav-item__button:hover .span-market {
  color: #00D7FF;
}

.nav-item {
  padding-bottom: 2.2rem;
  @include tabletP {
    padding-bottom: 0;
  }

  .span-market {
    color: #00D3C4;

    &:hover,
    &--active {
      color: $activeBlue;
    }
  }

  & + .nav-item {
    @include desk {
      margin-left: 3.5rem;
    }
  }
  &:hover {
    .nav-item__submenu {
      @include tabletP {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
      }
    }
    .nav-item__button {
      @include tabletP {
        color: $activeBlue;
      }
      &::after {
        @include tabletP {
          pointer-events: all;
          z-index: 1;
        }
      }
    }
  }
  &__button {
    font-size: 1.6rem;
    color: $primaryWhite;
    text-transform: uppercase;
    font-weight: $fontWeightBold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    @include desk {
      min-height: 12.6rem;
    }
    &::after {
      @include tabletP {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 2.7rem;
        max-width: 96.4rem;
        height: 3.4rem;
        background: 0 0;
        pointer-events: none;
      }
      @include desk {
        max-width: 99.8rem;
        top: 7rem;
        height: 5.8rem;
      }
    }
    &:hover {
      @include desk {
        color: $hoverOrange;
      }
    }
    &--active {
      color: $activeBlue;
    }
    .icon-arrowMenu {
      font-size: 0.8rem;
      display: inline-block;
      transition: 0.3s ease-in-out;

      @include tabletP {
        display: none;
      }
    }
  }
  &__submenu {
    overflow: hidden;
    transition: 0.3s ease-in-out;
    height: 0;
    @include tabletP {
      opacity: 0;
      transition: opacity 0.3s, transform 0.5s;
      transform: translateY(20px);
      pointer-events: none;
      height: auto;
      position: absolute;
      background-color: $primaryViolet;
      top: 5.9rem;
      left: 0;
      width: 100%;
    }
    @include desk {
      top: 12.6rem;
    }
  }
  &.active {
    .nav-item__submenu {
      @include tabletP {
        display: block;
      }
    }
    .nav-item__button {
      @include tabletP {
        color: $activeBlue;
      }
      .icon-arrowMenu {
        transform: rotate(180deg);
        transition: 0.3s ease-in-out;
      }
    }
  }
}
.submenu {
  &__wrapper {
    @include tabletP {
      max-width: 70.7rem;
      margin-inline: auto;
      padding-top: 1.5rem;
      padding-bottom: 2.3rem;
      border-top: 1px solid #8451FF;
    }
    @include tabletL {
      max-width: 96.4rem;
    }
    @include desk {
      padding-left: 0;
      padding-right: 0;
      padding-top: 1.8rem;
      padding-bottom: 3.8rem;
      max-width: 99.8rem;
      display: flex;
      justify-content: space-between;
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    @include tabletP {
      margin-top: 0;
      width: 16.3rem;
    }
    @include desk {
      width: auto;
      padding-right: 3rem;
    }
  }
  &__top-link {
    padding: 1.1rem 0 1.2rem;
    font-size: 1.6rem;
    color: $primaryWhite;
    text-transform: uppercase;
    font-weight: $fontWeightMedium;
    @include tabletP {
      margin-bottom: 0.8rem;
      padding: 1rem 0 1.1rem;
    }
    @include desk {
      margin-bottom: 0;
    }
    &:hover {
      color: $hoverOrange;
    }
    &--active {
      color: $activeBlue;
    }
  }
  &__link {
    padding: 1rem 0;
    margin-top: 0.5rem;
    font-size: 1.6rem;
    color: #B89AFF;
    @include tabletP {
      margin-top: 0.2rem;
    }
    &:hover {
      color: $hoverOrange;
    }
    &--active {
      color: $activeBlue;
    }
  }
  &__buttons {
    margin-top: 3.1rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 1.9rem;
    @include tabletP {
      margin-top: 1.5rem;
      padding-bottom: 0;
    }
    @include desk {
      margin-top: 0.7rem;
      flex-direction: column;
      align-items: flex-start;
    }
    a {
      margin-right: 2.2rem;
      margin-bottom: 2.9rem;
      @include desk {
        margin-right: 1.6rem;
        margin-bottom: 2.5rem;
      }
    }
  }
  &__links {
    margin-top: 1.9rem;
    @include tabletP {
      margin-top: 0;
      display: flex;
      justify-content: space-between;
    }
    div:not(:first-child) {
      margin-top: 2.2rem;
      @include tabletP {
        margin-top: 0;
      }
    }
  }
}
