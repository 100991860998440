.wave {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 1rem;
  left: 4.5rem;
  z-index: -1;
  @include tabletP {
    left: 17.5rem;
    top: -4.9rem;
  }
  @include desk {
    top: -3.3rem;
    left: 16.9em;
  }
  span {
    font-size: 7.7rem;
    margin-bottom: 0.2rem;
    @include tabletP {
      font-size: 6.4rem;
    }
    @include desk {
      font-size: 8.4rem;
    }
  }
  &--slider {
    z-index: 0;
    left: 0;
    top: -6.6rem;
    @include tabletP {
      left: 28.5rem;
      top: -9rem;
    }
    @include desk {
      left: 24rem;
      top: -3rem;
    }
    span {
      font-size: 4.7rem;
      @include tabletP {
        font-size: 6.4rem;
      }
      @include desk {
        font-size: 8.4rem;
      }
    }
  }
  &--featured-posts {
    left: 13.4rem;
    top: auto;
    bottom: 0.6rem;
    @include tabletP {
      left: 28.5rem;
      bottom: 0.3rem;
    }
    @include desk {
      left: 35.1rem;
      bottom: -1.6rem;
    }
    span {
      font-size: 6.6rem;
      margin-bottom: 0;
      @include desk {
        font-size: 8.5rem;
      }
    }
  }
  &--home-slider {
    top: -5.7rem;
    @include tabletP {
      top: -7rem;
    }
    @include desk {
      top: 3rem;
    }
  }
  &--adoptions {
    left: auto;
    right: 78.7rem;
    top: 7.8rem;
    @include tabletP {
      right: 114.7rem;
      top: 10.2rem;
    }
    @include desk {
      right: 183rem;
    }
    span {
      font-size: 7.6rem;
      margin-bottom: 1rem;
      opacity: 0.3;
      @include tabletP {
        font-size: 8.6rem;
        margin-bottom: 0;
      }
    }
  }
  &--rules {
    top: -6rem;
    left: 7.5rem;
    @include tabletP {
      top: -9.9rem;
      left: 17.4rem;
    }
    @include desk {
      top: -12.5rem;
      left: 25.5rem;
    }
    span {
      font-size: 3.2rem;
      @include tabletP {
        font-size: 6rem;
      }
      @include desk {
        font-size: 8rem;
        margin-bottom: 0.6rem;
      }
    }
  }
}
