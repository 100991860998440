.listing {
  margin-bottom: 3.1rem;
  @include tabletP {
    margin-bottom: 4rem;
  }
  @include desk {
    margin-bottom: 8rem;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.9rem;
  }
  &__item {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 0.9rem;
    margin-bottom: 3.3rem;
    @include tabletP {
      margin-bottom: 1.7rem;
    }
    @include tabletL {
      flex-basis: calc(100% / 3);
      margin-bottom: 1.2rem;
    }
    @include desk {
      flex-basis: calc(100% / 4);
      margin-bottom: 1.2rem;
    }
  }
  &--inline {
    @include tabletP {
      margin-bottom: 2rem;
    }
    .listing__item {
      flex-basis: 100%;
      border-bottom: 1px solid $secondaryGrey;
      padding-bottom: 1.8rem;
      margin-bottom: 0.1rem;
      @include tabletP {
        padding-bottom: 1.6rem;
        margin-bottom: 0.7rem;
      }
      @include desk {
        padding-left: 17.8rem;
        border-bottom: none;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }
      &:last-of-type {
        border-bottom: none;
        padding-bottom: 0.5rem;
      }
    }
  }
  &--recommended {
    margin-top: 3.2rem;
    @include desk {
      margin: 4rem 0 6.1rem;
    }
    h1 {
      color: $primaryViolet;
      margin-bottom: 1.8rem;
      @include tabletP {
        margin-bottom: 1.1rem;
      }
      @include desk {
        margin-bottom: 2.6rem;
      }
    }
    .listing__item {
      padding: 0 1rem;
      @include tabletP {
        margin-bottom: 0.6rem;
      }
      // @include tabletP {
      //   flex-basis: calc(100% / 3);
      //   margin-bottom: 3.2rem;
      // }
      @include desk {
        margin-bottom: 1.4rem;
      }
    }
  }
  &--specialists {
    .listing {
      &__item {
        margin-bottom: 2.9rem;
        @include tabletP {
          margin-bottom: 2.2rem;
        }
        @include desk {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  &--breeds {
    .listing {
      &__item {
        margin-bottom: 1.8rem;
        @include tabletP {
          margin-bottom: 1.5rem;
        }
        @include desk {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
