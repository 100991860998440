.ad {
  &__content {
    display: flex;
    justify-content: center;
  }
  &__label {
    display: block;
    margin-bottom: 1rem;
  }
  &--header {
    background: #FFFFFF;
    .ad {
      &__content {
        display: flex;
        height: 7.2rem;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        @include tabletP {
          height: 9.4rem;
        }
        @include desk {
          height: 10rem;
        }
        picture {
          width: 100%;
          object-fit: fill;
        }
      }
    }
  }
  &--content {
    margin: 3rem 0;
    @include tabletP {
      margin: 4rem 0;
    }
    @include desk {
      margin: 8rem 0;
    }
    .ad {
      &__content {
        height: 8.5rem;
        @include tabletP {
          height: 14.2rem;
        }
        @include tabletL {
          height: 20rem;
        }
        img {
          object-fit: contain;
        }
      }
    }
  }
  &--sidebar-small {
    margin-bottom: 2rem;
    .ad {
      &__content {
        display: block;
        height: 25.3rem;
        @include tabletP {
          height: 17.5rem;
        }
        @include tabletL {
          height: 25rem;
        }
      }
      &__picture {
        height: 100%;
        img {
          object-fit: contain;
          //@include tabletP {
          //  object-fit: cover;
          //}
        }
      }
    }
  }
  &--sidebar-big {
    .ad {
      &__content {
        display: block;
        height: 71.6rem;
        @include tabletP {
          height: 49.4rem;
        }
        @include tabletL {
          height: 44.7rem;
        }
      }
      &__picture {
        height: 100%;
        img {
          object-fit: contain;
          //@include tabletP {
          //  object-fit: cover;
          //}
        }
      }
    }
  }
}
