.swiper {
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 1.7rem;
    left: auto;
    right: -0.4rem;
    width: auto;
    @include tabletP {
      bottom: 4.1rem;
    }
    @include desk {
      bottom: 3rem;
      right: 1.8rem;
    }
    .swiper-pagination-bullet {
      transition: $transition-base;
      cursor: pointer;
      width: 1.1rem;
      height: 1.1rem;
      background-color: $secondaryGreen;
      opacity: 1;
      &:hover {
        background-color: $primaryBlue;
      }
      &-active {
        background-color: $primaryBlue;
      }
    }
  }
  .btn--pagination {
    position: absolute;
    top: 50%;
    margin-top: -1.4rem;
    &-next {
      right: 0.9rem;
      @include tabletP {
        right: 1.7rem;
      }
    }
    &-prev {
      left: 0.9rem;
      @include tabletP {
        left: 1.7rem;
      }
    }
  }
}
.swiper-wrapper {
  padding-bottom: 3.6rem;
  @include tabletP {
    padding-bottom: 3.2rem;
  }
  @include desk {
    padding-bottom: 3.8rem;
  }
}
