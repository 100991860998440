.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
  transition: $transition-base;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  color: $primaryWhite;
  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(3, 3, 3, 0.3);
    transition: $transition-base;
  }
  &--hide {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  &__container {
    padding: 4.2rem 0;
    background-color: $primaryViolet;
    position: relative;
    z-index: 1;
  }
  &__content {
    display: flex;
    flex-direction: column;
    @include tabletP {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }
  &__text {
    h2 {
      margin-bottom: 0.8rem;
      @include tabletP {
        margin-bottom: 1rem;
      }
    }
    h6 {
      font-weight: $fontWeightRegular;
    }
  }
  &__action {
    margin-top: 2rem;
    .btn {
      &--link {
        color: $primaryWhite;
        &:hover {
          color: $hoverOrange;
          span {
            &::before {
              color: $hoverOrange;
            }
          }
        }
        span {
          &::before {
            color: $primaryWhite;
          }
        }
      }
      &.cookies__accept {
        margin-right: 2rem;
        @include tabletP {
          margin-right: 2.3rem;
        }
      }
    }
  }
}
