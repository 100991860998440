.top-header-container {
  padding-left: 0;
  padding-right: 0;
}
.top-header {
  position: relative;
  width: 100%;
  z-index: 3;
  background-color: $primaryWhite;
  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
  &__content {
    justify-content: space-between;
    align-items: center;
    @include desk {
      padding-top: 0.3rem;
    }
  }
}
.swiper-button-next {
  right: 0;
  background-image: url("/static/fonts/arrow.svg");
}
.swiper-button-prev {
  left: 0;
  color: #000;
  transform: rotate(180deg);
  background-image: url("/static/fonts/arrow.svg");

  &::after {
    margin-left: 10px;
    content: "x";
  }
}

.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  padding: 8px 16px;
  border-radius: 100%;
}

.swiper-button-next:after, .swiper-button-prev:after {
  display: none;
}

.panel.topheader {
  .top-bar {
    position: relative;
    padding: 0 40px;

    @include tabletL {
      padding: 0 0;
    }
    @include desk {
      padding: 0 0;
    }

    .swiper-wrap {
      padding-bottom: 0;
    }
    .swiper-slide {
      width: fit-content;
    }
    .button-link {
      font-weight: $fontWeightBold;
      color: $primaryViolet;
      font-size: 1.6rem;
      padding-right: 25px;
      padding-bottom: 7px;
      padding-top: 7px;

      &:hover,
      &:active {
        transition: opacity 0.3s ease;
        color: $primaryBlue;

        .market {
          transition: opacity 0.3s ease;
          color: $primaryBlue !important;
        }
        .pethomer {
          transition: opacity 0.3s ease;
          color: $primaryBlue !important;
        }
        .klubpupila {
          transition: opacity 0.3s ease;
          color: $primaryBlue !important;
        }
      }

      .link-span {
        color: $primaryViolet;

        &.market {
          color: $zoocialMarketColor;
        }
        &.pethomer {
          color: $zoocialPethomerColor;
        }
        &.klubpupila {
          color: $zoocialKlubPupilaColor;
        }

        &:hover,
        &:active {
          transition: opacity 0.3s ease;
          color: $primaryBlue;
        }
      }
    }
  }
}

