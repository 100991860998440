.featured-posts {
  background-color: $sectionBgrd;
  padding: 2.6rem 0 3rem;
  @include tabletP {
    padding: 4rem 0 0.6rem;
  }
  @include desk {
    padding: 7.6rem 0 5.6rem;
  }
  &__header {
    margin-bottom: 0.5rem;
    @include tabletP {
      margin-bottom: 0.6rem;
    }
    @include desk {
      margin-bottom: 0.1rem;
    }
  }
}
