.newsletter {
  &__content {
    background-color: $primaryViolet;
    color: $primaryWhite;
    padding-top: 3.3rem;
    padding-bottom: 3.2rem;
    @include desk {
      padding-top: 9rem;
      padding-bottom: 9rem;
    }
  }
  h3, .paragraph-x-large {
    display: inline;
    @include tabletP {
      display: block;
    }
  }
  .message {
    color: $primaryBlack;
  }
  .form {
    margin-top: 1.8rem;
    margin-bottom: 4rem;
    @include tabletP {
      margin-top: 2.1rem;
      margin-bottom: 2.5rem;
    }
    @include desk {
      margin-top: 3.2rem;
      margin-bottom: 2.9rem;
    }
    &__row {
      &--two {
        @include tabletP {
          flex-wrap: wrap;
        }
        @include desk {
          flex-wrap: nowrap;
        }
        .form__group {
          @include desk {
            width: calc(41% - 1rem);
          }
        }
        .form__actions {
          margin-top: 2.4rem;
          @include tabletP {
            margin-top: 0.7rem;
          }
          @include desk {
            width: calc(16% - 1rem);
            margin-top: 2.2rem;
          }
        }
      }
    }
    &__input {
      background-color: $primaryWhite;
    }
    &__group {
      &--checkbox {
        margin-top: 3.7rem;
        @include tabletP {
          margin-top: 2rem;
          margin-bottom: 1.8rem;
        }
        @include desk {
          width: 83%;
          margin-top: 1.4rem;
          margin-bottom: 0.6rem;
        }
        label {
          a {
            color: $primaryWhite;
            &:hover {
              color: $hoverOrange;
            }
          }
        }
      }
    }
  }
}
