.details-icon {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 0.7rem;
  @include tabletP {
    margin-top: 0.4rem;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  &__item {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: $thirdGrey;
    margin-bottom: 0.3rem;
    min-width: 47%;;
    @include tabletP {
      margin-bottom: 0;
      min-width: auto;
    }
    span {
      font-size: 1.5rem;
      padding-right: 0.5rem;
      &.icon-size, &.icon-medal {
        font-size: 1.1rem;
      }
    }
  }
  &--label {
    @include tabletP {
      justify-content: flex-start;
    }
    .details-icon__item {
      background-color: $sectionBgrd;
      border-radius: 1.8rem;
      padding: 0.4rem 1.1rem;
      min-width: auto;
      color: $primaryBlack;
      margin: 0 1.4rem 1.7rem 0;
      span{
        vertical-align: middle;
        &::before {
          color: $primaryBlack;
        }
      }
    }
  }
  &--adoption {
    justify-content: flex-start;
    .details-icon__item + .details-icon__item {
      margin-left: 1.2rem;
    }
    .details-icon__item {
      span {
        font-size: 1.2rem;
        padding-right: 0;
      }
      .icon-point:before {
        color: $thirdGrey;
      }
    }
  }
  &--specialist {
    margin-bottom: 0;
    .details-icon__item {
      display: flex;
      align-items: center;
      color: $primaryBlack;
      span {
        padding-right: 0;
      }
      .icon-point {
        margin-right: 0.6rem;
      }
    }
  }
}
