.images-banner {
  margin-top: 1.8rem;
  margin-bottom: 4rem;
  position: relative;
  @include tabletP {
    margin-top: 0.7rem;
    margin-bottom: 2.5rem;
  }
  @include desk {
    margin-top: 3rem;
  }
  &--place {
    margin-bottom: 2rem;
  }
  &__img {
    position: relative;
    height: 27.1rem;
    @include tabletP {
      height: 47.8rem;
    }
    @include desk {
      height: 60rem;
    }
    img {
      object-fit: cover;
      object-position: top center;
      @include tabletP {
        object-position: center;
      }
    }
  }
  &__content {
    position: relative;
    height: 27.1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    @include tabletP {
      height: 47.8rem;
    }
    @include desk {
      height: 60rem;
    }
    .details-icon {
      display: none;
      @include tabletP {
        display: block;
        margin-top: 0;
        margin-bottom: 1.8rem;
      }
    }
  }
  &__swiper-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  &__swiper {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(250.58deg, rgba(0, 0, 0, 0) 36.97%, rgba(0, 0, 0, 0.5) 91.99%);
      z-index: 1;
    }
    .swiper-slide {
      padding: 0;
    }
    .swiper-wrapper {
      padding-bottom: 0;
    }
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      right: 2rem;
      @include tabletP {
        bottom: 2.4rem;
      }
      @include desk {
        right: 3rem;
      }
    }
  }
  &__info {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0.7rem;
    padding-left: 2.5rem;
    max-width: 75%;
    @include tabletP {
      position: absolute;
      margin-top: 0;
      left: 0;
      bottom: 0;
      padding-left: 3.2rem;
      padding-bottom: 1.4rem;
    }
    h1 {
      color: $activeBlue;
      margin-bottom: 0.9rem;
      @include tabletP {
        margin-bottom: 1.5rem;
      }
    }
  }
  &__location {
    color: $primaryWhite;
    font-size: 1.4rem;
    margin-bottom: 1.8rem;
    position: relative;
    padding-left: 1.8rem;
    .icon-point {
      position: absolute;
      top: 0.2rem;
      left: 0;
      @include tabletP {
        top: 0.5rem;
      }
      &::before {
        color: $activeBlue;
      }
    }
  }
  &__post-code {
    display: block;
    @include tabletP {
      display: inline;
    }
  }
  &__mobile-content {
    margin-top: 1.6rem;
    @include tabletP {
      display: none;
    }
  }
  &--ad {
    margin: 3rem 0;
    @include tabletP {
      margin: 4rem 0;
    }
    @include desk {
      margin: 8rem 0;
    }
    .images-banner {
      &__img {
        height: 11.5rem;
        @include tabletP {
          height: 19rem;
        }
        @include tabletL {
          height: 25.9rem;
        }
        @include desk {
          height: 26.1rem;
        }
        img {
          object-fit: contain;
        }
      }
      &__content {
        height: 11.5rem;
        @include tabletP {
          height: 19rem;
        }
        @include tabletL {
          height: 25.9rem;
        }
        @include desk {
          height: 26.1rem;
        }
      }
    }
    .swiper-wrapper {
      padding: 0;
    }
    .swiper {
      &::after {
        display: none;
      }
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 1rem;
      }
    }
  }
}
