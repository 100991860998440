.post-thumbnail {
  &:hover {
    h5, h4 {
      color: $hoverOrange;
    }
    .btn--link {
      color: $hoverOrange;
      span::before {
        color: $hoverOrange;
      }
    }
    .post-thumbnail {
      &__author{
        color: $primaryBlack;
        strong {
          color: $hoverOrange;
        }
      }
      &__item {
        color: $primaryBlack;
      }
    }
  }
  & * {
    transition: $transition-base;
  }
  &__tags {
    margin-top: 1.4rem;
    @include tabletP {
      margin-top: 0.6rem;
      min-height: auto;
    }
  }
  h5 {
    color: $primaryViolet;
    margin-top: 1rem;
    @include tabletP {
      margin-top: 0.7rem;
    }
    @include desk {
      margin-top: 0.8rem;
    }
  }
  &__author {
    color: $primaryGrey;
    margin-top: 0.9rem;
    @include tabletP {
      margin-top: 1rem;
    }
    strong {
      margin-right: 0.1rem;
    }
  }
  &__teaser {
    display: none;
    @include tabletP {
      display: block;
      margin-top: 1rem;
    }
  }
  &__position {
    color: $primaryGrey;
    margin-bottom: 1.7rem;
    @include tabletP {
      margin-bottom: 1.5rem;
    }
  }
  img {
    height: 10.2rem;
    width: auto;
    margin: 0 auto;
    @include tabletP {
      height: 14.8rem;
    }
    @include desk {
      height: 15.6rem;
    }
  }
  &.sidebar__item{
    .post-thumbnail__picture {
      img {
        max-height: 15.5rem;
        @include tabletP {
          max-height: 12.1rem;
        }
        @include desk {
          max-height: 12.9rem;
        }
      }
    }
  }
  &--inline {
    @include desk {
      position: relative;
      min-height: 15.1rem;
    }
    h4 {
      color: $primaryViolet;
      margin-top: 0.9rem;
      margin-bottom: 1rem;
      @include tabletP {
        margin-top: 0.7rem;
      }
      @include desk {
        margin-top: 0.9rem;
      }
    }
    .post-thumbnail {
      &__teaser {
        display: block;
      }
      &__picture {
        display: none;
        @include desk {
          display: block;
          position: absolute;
          top: 1rem;
          left: 0.9rem;
        }
        img {
          @include desk {
            width: 14.9rem;
            height: auto;
            max-height: 14.1rem;
          }
        }
      }
    }
  }
  &--product {
    width: 100%;
    &.sidebar__item {
      margin-bottom: 0;
      @include desk {
        margin-bottom: 1.1rem;
      }
    }
    h3 {
      margin-top: 0.8rem;
      a {
        color: $primaryViolet;
        &:hover {
          color: $hoverOrange;
        }
      }
    }
    .post-thumbnail {
      &__teaser {
        display: block;
        margin-top: 1.1rem;
        @include desk {
          margin-top: 1.1rem;
          margin-bottom: 0.4rem;
        }
      }
      &___caption {
        color: $primaryGrey;
        @include desk {
          margin-top: 0.3rem;
        }
      }
      &__wrapper {
        background-color: $primaryWhite;
      }
      &__picture {
        margin: 1.4rem auto 1.9rem;
        max-width: none;
        background-color: $primaryWhite;
        padding: 1rem 0;
        @include tabletP {
          margin: 2.7rem auto 1.9rem;
        }
        @include desk {
          margin: 1.1rem auto 2.2rem;
        }
        img {
          margin: 0 auto;
          max-height: none;
        }
      }
      &__price {
        display: block;
        font-size: 1.6rem;
        color: $primaryOrange;
        margin-top: 1rem;
        font-weight: $fontWeightBold;
      }
    }
    .dynamic-product__label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: $fontWeightRegular;
    }
  }
  &--breed {
    .post-thumbnail__teaser {
      display: block;
    }
  }
  &--adoption {
    h5 {
      margin-top: 1.4rem;
      @include tabletP {
        margin-top: 0.8rem;
      }
      @include desk {
        margin-top: 0.9rem;
      }
    }
    .btn {
      @include desk {
        margin-bottom: 1.2rem;
      }
    }
    .post-thumbnail {
      &__teaser {
        display: block;
        margin-bottom: 0.9rem;
        @include tabletP {
          margin-bottom: 0.6rem;
        }
        @include desk {
          margin-bottom: 1rem;
        }
      }
      &__picture {
        border-radius: 0.6rem;
        overflow: hidden;
        height: 10.2rem;
        @include tabletP {
          height: 13.5rem;
        }
        @include desk {
          height: 14.1rem;
        }
      }
    }
  }
  &--specialist {
    h5 {
      margin-top: 0.3rem;
      @include tabletP {
        margin-top: 0.6rem;
      }
    }
    p {
      margin-top: 1rem;
      @include tabletP {
        margin-top: 0.6rem;
      }
    }
    .post-thumbnail {
      &__picture {
        width: 100%;
        display: inline-block;
        @include tabletP {
          margin-bottom: 0.6rem;
        }
        img {
          height: 9.2rem;
          width: 100%;
          display: inline-block;
          border-radius: 0.6rem;
          max-height: unset;
          @include tabletP {
            height: 13.5rem;
          }
          @include desk {
            height: 14.16rem;
          }
        }
      }
    }
  }
  &--breeds {
    img {
      max-height: 9.2rem;
      @include tabletP {
        max-height: 13.4rem;
      }
      @include desk {
        max-height: 14.1rem;
      }
    }
    h5 {
      margin-bottom: 1.1rem;
      @include tabletP {
        margin-top: 1.6rem;
      }
      @include desk {
        margin-top: 1rem;
      }
    }
    .btn {
      margin-top: 1.3rem;
    }
  }
  &--search {
    h5 {
      margin-top: 0.8rem;
      @include tabletP {
        margin-top: 1.2rem;
      }
    }
    .post-thumbnail__picture {
      border-radius: 0.6rem;
      overflow: hidden;
      height: 9.2rem;
      @include tabletP {
        height: 13.3rem;
      }
      @include desk {
        height: 14.1rem;
      }
    }
  }
}
