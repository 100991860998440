.form {
  &__row {
    @include tabletP {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .form__group {
      @include tabletP {
        width: 100%;
      }
    }
    &--two {
      .form__group {
        @include tabletP {
          width: calc(50% - 1rem);
        }
      }
    }
    &--three {
      .form__group {
        @include tabletP {
          width: calc(33.333% - 1.4rem);
          margin: 0;
        }
      }
    }
    &:last-of-type .form__group--checkbox {
      @include tabletP {
        margin-bottom: 0;
      }
    }
  }
  &__actions {
    margin: 0 0 3.4rem;
    @include tabletP {
      margin: 0.9rem 0 3.6rem;
    }
  }
  &__input {
    width: 100%;
    background-color: transparent;
    border-radius: 0.4rem;
    border: 1px solid $primaryViolet;
    font-size: 1.4rem;
    line-height: 2.4rem;
    padding: 0.4rem 0.9rem;
    font-family: $fontFamilyPrimary;
    &::placeholder {
      color: #717171;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #717171;
    }
    &::-ms-input-placeholder {
      color: #717171;
    }
    &--hidden {
      display: none;
    }
  }
  &__label {
    font-size: 1.2rem;
    line-height: 2.4rem;
    display: block;
  }
  &__count {
    color: #D9D9D9;
    font-size: 1.2rem;
    line-height: 2.4rem;
  }
  &__group {
    transition: $transition-base;
    margin-bottom: 1.7rem;
    &--error {
      .form__input {
        border-color: $primaryError;
      }
    }
    &--success {
      color: $primaryGreen;
    }
    &__warninig {
      font-size: 1rem;
      line-height: 1.7rem;
      color: $primaryError;
    }
    &--disabled {
      .form {
        &__label {
          color: $secondaryGrey;
        }
        &__input {
          border-color: $secondaryGrey;
          color: $secondaryGrey;
        }
      }
    }
    &--search {
      .choices[data-type*=select-one] .choices__list--single .choices__placeholder {
        &::before {
          content: "\e929";
          color: #4e25b0;
          font-family: 'icomoon' !important;
          margin: 0 1rem 0 0.4rem;
        }
      }
    }
    &--rating {
      @include tabletP {
        margin-bottom: 1.3rem;
      }
      .icon-star {
        font-size: 2.5rem;
        margin-right: 0.1rem;
        cursor: pointer;
        @include tabletP {
          font-size: 3rem;
          margin-right: 0;
        }
        &::before {
          transition: $transition-base;
        }
        &--active::before {
          content: "\e925";
        }
      }
    }
    &--textarea {
      position: relative;
      margin-bottom: 0.2rem;
      @include desk {
        margin-bottom: 0.4rem;
      }
      .form__input {
        resize: none;
        height: 14.1rem;
      }
      .form__count {
        position: absolute;
        top: 13.9rem;
        right: 1.3rem;
      }
    }
    &--checkbox {
      margin-bottom: 2.5rem;
      @include tabletP {
        margin-bottom: 3.7rem;
      }
      @include desk {
        margin-top: 1.5rem;
        margin-bottom: 0;
      }
      input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        &:checked + label::after {
          content: '';
          display: block;
          position: absolute;
          top: 5px;
          left: 8px;
          width: 6px;
          height: 8px;
          border: solid $primaryViolet;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          @include tabletP {
            top: 5px;
            left: 7px;
          }
        }
      }
      label {
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 1.4rem;
        line-height: 2.1rem;
        padding: 0 0 0 3.2rem;
        @include desk {
          font-size: 1.6rem;
        }
        &.form__label--small {
          font-size: 1rem;
          line-height: 1.5rem;
          padding-left: 4rem;
          & + .form__group__warninig {
            padding-left: 4rem;
          }
        }
        a {
          text-decoration: underline;
          transition: $transition-base;
          &:hover {
            color: $hoverOrange;
          }
        }
        &::before {
          content: '';
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid $primaryViolet;
          background-color: $primaryWhite;
          border-radius: 2px;
          top: -1px;
          left: 0;
          padding: 1rem;
          display: inline-block;
          position: absolute;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 0.5rem;
          @include tabletP {
            top: -1px;
            left: -1px;
          }
        }
      }
      &.form__group--disabled {
        label {
          cursor: default;
          color: $secondaryGrey;
          &::before {
            cursor: default;
            border-color: $secondaryGrey;
          }
        }
        input:checked + label::after {
          border-color: $secondaryGrey;
        }
      }
      &.form__group--error {
        .form__group__warninig {
          padding-left: 3.2rem;
        }
      }
      &.form__group--filter {
        label {
          font-size: 1.2rem;
          line-height: 1.8rem;
          &::before {
            padding: 0.8rem;
            @include tabletP {
              left: 0;
            }
          }
        }
        input:checked+label::after {
          top: 0.3rem;
          left: 0.6rem;
          width: 0.5rem;
        }
      }
    }
    &--radio {
      margin-bottom: 2.2rem;
      p {
        margin-bottom: 1.5rem;
      }
      input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        &:checked + label::after {
          content: '';
          display: block;
          position: absolute;
          top: 0.4rem;
          left: 0.4rem;
          width: 1.2rem;
          height: 1.2rem;
          background-color: $primaryBlue;
          border-radius: 1rem;
        }
      }
      label {
        position: relative;
        cursor: pointer;
        font-size: 1.4rem;
        line-height: 2.1rem;
        padding: 0 0 0 3.7rem;
        display: inline-block;
        margin-right: 4rem;
        &::before {
          content: '';
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid $primaryViolet;
          background-color: $primaryWhite;
          border-radius: 2rem;
          top: -1px;
          left: 0;
          padding: 1rem;
          display: inline-block;
          position: absolute;
          vertical-align: middle;
          cursor: pointer;
          @include tabletP {
            top: -1px;
            left: -1px;
          }
        }
      }
      &.form__group--disabled {
        p {
          margin-bottom: 1rem;
          color: $primaryViolet;
        }
        label {
          color: $primaryBlack;
          cursor: default;
          display: none;
          padding-left: 0;

        }
        input:checked + label {
          display: block;
          &::before, &::after {
            display: none;
          }
        }
      }
    }
  }
}
