.about {
  background: $sectionBgrd;
  margin-top: 2rem;
  padding: 2.5rem 0 3.7rem;
  @include tabletP {
    padding: 3.9rem 0 4.4rem;
  }
  @include desk {
    padding: 7.5rem 0 15rem;
  }
  .container {
    position: relative;
  }
  h2 {
    margin-bottom: 1.2rem;
    @include tabletP {
      margin-bottom: 2.6rem;
      width: 100%;
    }
  }
  strong {
    color: $primaryViolet;
  }
  .paragraph-large {
    margin-bottom: 1.3rem;
    @include tabletP {
      margin-bottom: 2.6rem;
    }
    @include desk {
      margin-bottom: 1.9rem;
    }
  }
  ul {
    li {
      position: relative;
      @include desk {
        margin-top: -0.3rem;
      }
      &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 4px;
        height: 4px;
        background: $primaryViolet;
        border-radius: 1rem;
        margin: 0 0.7rem 0 0.8rem;
      }
    }
  }
  &__picture {
    margin-top: 1.9rem;
    @include tabletP {
      margin-top: 0.5rem;
      width: 50%;
    }
    @include desk {
      position: absolute;
      right: 0;
      bottom: -5.5rem;
      width: auto;
    }
  }
  &__content {
    @include tabletP {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @include desk {
      width: 46%;
    }
  }
  &__half-paragraph {
    @include tabletP {
      width: 48%;
    }
    @include desk {
      width: 100%;
    }
  }
}
