@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

h1, h2, h3, h4, h5 {
  font-family: $fontFamilyPrimary;
  font-weight: $fontWeightBold;
}

h1 {
  font-size: 2rem;
  line-height: 3rem;
  @include tabletP {
    font-size: 2.8rem;
    line-height: 3.7rem;
  }
  @include desk {
    font-size: 3.2rem;
    line-height: 3.7rem;
  }
}

h2 {
  font-size: 1.4rem;
  line-height: 2.1rem;
  text-transform: uppercase;
  @include tabletP {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  @include desk {
    font-size: 2rem;
    line-height: 3rem;
  }
}

h3 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  @include desk {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }
}

h4 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

h5 {
  font-size: 1.4rem;
  line-height: 2.1rem;
}

h6 {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

small, .paragraph-small {
  font-size: 1.2rem;
  line-height: 1.8rem;
}
.paragraph-large {
  font-size: 1.4rem;
  line-height: 2.4rem;
  @include desk {
    font-size: 1.6rem;
    line-height: 2.7rem;
  }
}
.paragraph-x-large {
  font-size: 1.6rem;
  line-height: 2.4rem;
  @include tabletP {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }
}
.paragraph-info {
  font-size: 1rem;
  line-height: 1.5rem;
}

p {
  strong, a {
    color: $primaryViolet;
  }
  a {
    transition: $transition-base;
    &:hover {
      color: $hoverOrange;
    }
  }
}
