$primaryViolet: #4E25B0;
$primaryWhite: #ffffff;
$primaryBlack: #000000;
$primaryGrey: #888888;
$secondaryGrey: #D9D9D9;
$thirdGrey: #717171;
$sectionBgrd: #E6F8FF;
$footerBgrd: #F7FCFF;
$rightBoxBgrd: #D3EEEA;
$waveClr: #D8F4FF;
$secondaryBtn: #18A1E7;
$primaryBtn: #ACECF8;
$secondaryError: #FFE6E4;
$primaryError: #DE1306;
$primaryBlue: #00B5FF;
$activeBlue: #00D7FF;
$paginationBtn: #b3f3ff;
$secondaryGreen: #60D1C5;
$primaryGreen: #24AB98;
$hoverOrange: #F1A93C;
$primaryOrange: #E4700D;
$zoocialMarketColor: #00d3c4;
$zoocialPethomerColor: #f1a93c;
$zoocialKlubPupilaColor: $secondaryGreen;
